@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap');

$mobile: 688px;
$tablet: 992px;
$medium: 1312px;

:root {
  // Colors
  --background: #f8f8f8;

  // Other
  --shadow: 0px 1px 32px rgba(0, 0, 0, 0.05);
  --shadow-light: 0px 1px 16px rgba(0, 0, 0, 0.05);
}
