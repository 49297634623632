@import 'variables';

#portfolio {
  max-width: $mobile;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;

  h2 {
    margin-right: auto;
    margin-bottom: 2rem;
  }

  .project {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 2rem;

    .logo {
      max-width: 100px;
      max-height: 100px;
      margin: 0.5rem;
      margin-top: 0;
      margin-right: 1.5rem;
      border-radius: 999px;
      box-shadow: var(--shadow);
    }

    h4 {
      font-size: 1.1rem;
      font-weight: 800;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.9rem;
      margin: 0;
      margin-bottom: 1rem;
      line-height: 150%;
    }

    a {
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      margin-right: 0.5rem;
      color: black;

      svg {
        margin: auto;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: $mobile) {
  #portfolio {
    width: 90%;

    .project {
      .logo {
        width: 5rem;
        height: 5rem;
      }
    }
  }
}
