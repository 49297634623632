@import 'variables';

#about {
  .container {
    margin: 0 5%;
    width: 90%;
    max-width: 1440px;
  }

  div {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
  }

  .about {
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;

    h2 {
      align-self: flex-start;
      font-size: 1.4rem;
      font-weight: 800;
      margin-bottom: 0;
    }

    p {
      line-height: 2;
      font-size: 0.9rem;
    }

    a {
      font-weight: 700;
      color: black;
      text-decoration: none;
    }
  }

  .profile {
    display: flex;
    position: relative;
    flex: 1;
    box-sizing: border-box;
    align-items: center;
    padding: 2rem;
    width: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60%;
      border-radius: 50%;
      box-shadow: var(--shadow);
    }

    #matrix {
      font-family: 'Roboto Mono', monospace;
      opacity: 0.3;
      flex: 1;
      padding: 2rem;
      text-align: center;
      z-index: -1;
    }
  }
}

@media (max-width: $tablet) {
  #about {
    flex-direction: column-reverse;
    margin: auto;

    .container {
      flex-direction: column-reverse;
    }

    .about {
      align-items: flex-start;
      padding: 0;
    }

    .profile {
      img {
        width: 60%;
      }
    }
  }
}

@media (max-width: $mobile) {
  #about {
    .profile {
      img {
        width: 80%;
      }
    }
  }
}
